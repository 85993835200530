import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import LinkArrowBlack from "@/assets/icons/link-arrow-black.svg";
import LinkArrowWhite from "@/assets/icons/link-arrow-white.svg";
import ContentfulImage from "@/components/ContentfulImage";
import FormattedHeadline from "@/components/FormattedHeadline";
import VideoPlayer from "@/components/VideoPlayer";
import MockInternalCTA from "@/components/buttons/mockInternal";
import FormattedDescription from "@/components/description/formatted-description";
import Eyebrow from "@/components/eyebrow";
import ImageSizes from "@/utils/helpers/images";

import styles from "./index.module.scss";

const renderExternalLink = (url, text, key) => {
  const isTextWhite = styles.color === "white";

  return (
    <a
      className={styles.moduleEditorialGrid2ColumnCtaBtn}
      href={url}
      key={key}
      rel="noopener noreferrer"
      style={{ color: styles.color }}
      target="_blank"
    >
      {text}
      {isTextWhite ? (
        <LinkArrowWhite aria-hidden={true} />
      ) : (
        <LinkArrowBlack aria-hidden={true} />
      )}
    </a>
  );
};

const renderInternalLink = (url, text, isThemeLight) => {
  //TODO: move this over to import { Link } from "Router" once all routes are moved to the new stack
  return (
    <MockInternalCTA
      className={styles.moduleEditorialGrid2ColumnInternalCtaBtn}
      isThemeLight={isThemeLight}
      text={text}
      url={url}
      withArrow={true}
    />
  );
};

const renderLibraryCTA = (libraryCTA) => {
  if (libraryCTA) {
    return libraryCTA.map((cta, index) => {
      const { text, url } = cta.fields;
      const key = `${text}-${index}`;

      return renderExternalLink(url, text, key);
    });
  }
};

const renderSubCopy = (subcopy, multiLineSubCopy) => {
  if (subcopy) {
    return <FormattedHeadline multiLine={subcopy} />;
  } else if (multiLineSubCopy) {
    return <FormattedDescription allowParagraphs richText={multiLineSubCopy} />;
  }

  return null;
};

const EditorialCard2Column = ({
  colIndex,
  subcopy,
  multiLineSubCopy,
  title,
  tag,
  tertiaryCopy,
  mediaFields,
  id,
  urlRef,
  eyebrow,
  cta,
  archiveImage,
  libraryCTA,
  isThemeLight,
  subtitle,
}) => {
  const image =
    get(mediaFields, "imageFile.fields") ||
    (archiveImage && archiveImage.fields);
  const video = get(mediaFields, "videoFile.fields");
  const posterSrc = get(mediaFields, "coverImageFile.fields.file.url", null);
  const altText = get(mediaFields, "imageFile.fields.description", "");
  let buttonLink;
  styles.color = isThemeLight ? "black" : "white";

  if (cta) {
    const { isExternal, url, text } = cta.fields;

    if (isExternal) {
      buttonLink = renderExternalLink(url, text);
    } else {
      buttonLink = renderInternalLink(url, text, isThemeLight);
    }
  }

  let externalLink = false;
  let hasHttp = false;

  if (urlRef && urlRef.length > 0) {
    externalLink = urlRef.includes("http") || urlRef.includes("www");
    hasHttp = urlRef.includes("http");
  }

  const finalUrlRef = !externalLink || hasHttp ? urlRef : "//" + urlRef;

  return (
    <div
      className={`${styles.moduleEditorialGrid2ColumnCard}`}
      key={id}
      style={{
        marginTop: `${colIndex * 120}px`,
      }}
    >
      <div
        className={`${styles.moduleEditorialGrid2ColumnMedia} position-relative w-100 h-0`}
      >
        {((image && !urlRef) || (image && urlRef && urlRef.length === 0)) && (
          <div
            className={`${styles.moduleEditorialGrid2ColumnImg} js-image position-absolute w-100 h-100`}
          >
            <ContentfulImage
              alt={altText}
              sizes={ImageSizes.card}
              src={image.file.url}
              title={image.title}
            />
          </div>
        )}
        {image && urlRef && urlRef.length > 0 && (
          <a
            className={styles.moduleEditorialGrid2ColumnUrlRef}
            href={finalUrlRef}
            rel="noreferrer"
            target={"_blank"}
          >
            <div
              className={`${styles.moduleEditorialGrid2ColumnImg} js-image position-absolute w-100 h-100`}
            >
              <ContentfulImage
                alt={altText}
                sizes={ImageSizes.card}
                src={image.file.url}
                title={image.title}
              />
            </div>
          </a>
        )}
        {video && (
          <div
            className={`${styles.moduleEditorialGrid2ColumnImg} position-absolute w-100 h-100`}
          >
            <VideoPlayer
              data={{
                posterSrc: posterSrc ? `https://${posterSrc}` : null,
                videoAsset: video.file,
              }}
              rewindOnPause={true}
            />
          </div>
        )}
      </div>
      {eyebrow && <Eyebrow {...eyebrow.fields} />}
      {urlRef ? (
        <a
          className={styles.moduleEditorialGrid2ColumnUrlRef}
          href={finalUrlRef}
          rel="noreferrer"
          target={"_blank"}
        >
          <h3
            className={styles.moduleEditorialGrid2ColumnTitle}
            style={{ color: styles.color }}
          >
            {title}
          </h3>
          {subtitle && (
            <p className={styles.moduleEditorialGrid2ColumnSubtitle}>
              {subtitle}
            </p>
          )}
          {subcopy ||
            (multiLineSubCopy && (
              <div
                className={styles.moduleEditorialGrid2ColumnSubcopy}
                style={{ color: styles.color }}
              >
                {renderSubCopy(subcopy, multiLineSubCopy)}
              </div>
            ))}
        </a>
      ) : (
        <>
          <h3
            className={styles.moduleEditorialGrid2ColumnTitle}
            style={{ color: styles.color }}
          >
            {title}
          </h3>
          {subtitle && (
            <p className={styles.moduleEditorialGrid2ColumnSubtitle}>
              {subtitle}
            </p>
          )}
          {subcopy ||
            (multiLineSubCopy && (
              <div
                className={styles.moduleEditorialGrid2ColumnSubcopy}
                style={{ color: styles.color }}
              >
                {renderSubCopy(subcopy, multiLineSubCopy)}
              </div>
            ))}
        </>
      )}
      <div className={styles.moduleEditorialGrid2ColumnCtaContainer}>
        {buttonLink}
        <br />
        {renderLibraryCTA(libraryCTA)}
      </div>

      {tag && tertiaryCopy && (
        <>
          <div>
            <span className={styles.moduleEditorialGrid2ColumnTag}>{tag}</span>
          </div>
          <p className={styles.moduleEditorialGrid2ColumnTertiaryCopy}>
            {tertiaryCopy}
          </p>
        </>
      )}
    </div>
  );
};

EditorialCard2Column.propTypes = {
  archiveImage: PropTypes.object,
  colIndex: PropTypes.number.isRequired,
  cta: PropTypes.object,
  eyebrow: PropTypes.string,
  id: PropTypes.string.isRequired,
  isThemeLight: PropTypes.bool,
  libraryCTA: PropTypes.array,
  mediaFields: PropTypes.object.isRequired,
  multiLineSubCopy: PropTypes.object,
  subcopy: PropTypes.string,
  subtitle: PropTypes.string,
  tag: PropTypes.string,
  tertiaryCopy: PropTypes.string,
  title: PropTypes.string.isRequired,
  urlRef: PropTypes.string.isRequired,
};

export default EditorialCard2Column;
